<template>
  <div class="repairs-list-wrapper">
    <div v-if="repairs_list">
      <table class="table table-sm text-center">
        <thead>
          <tr>
            <th v-if="USER_INFO.status === 'admin'">СЦ</th>
            <th>добавлен</th>
            <th>инструмент</th>
            <th>группа</th>
            <th>S№</th>
            <th>дата продажи</th>
            <th>дата ремонта</th>
            <th>дефект</th>
            <th>продавец</th>
          </tr>
          <tr>
            <th v-if="USER_INFO.status === 'admin'">
              <input
                class="hide form-control form-control-sm shadow-none"
                type="text"
              />
            </th>
            <th>
              <input
                class="hide form-control form-control-sm shadow-none"
                type="text"
              />
            </th>
            <th>
              <input
                class="hide form-control form-control-sm shadow-none"
                type="text"
              />
            </th>
            <th>
              <input
                class="hide form-control form-control-sm shadow-none"
                type="text"
              />
            </th>
            <th>
              <input
                class="hide form-control form-control-sm shadow-none"
                type="text"
              />
            </th>
            <th>
              <input
                class="hide form-control form-control-sm shadow-none"
                type="text"
              />
            </th>
            <th>
              <input
                class="hide form-control form-control-sm shadow-none"
                type="text"
              />
            </th>
            <th>
              <input
                class="hide form-control form-control-sm shadow-none"
                type="text"
              />
            </th>
            <th>
              <input
                class="hide form-control form-control-sm shadow-none"
                type="text"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="tr"
            v-for="(item, idx) in repairs_list"
            :key="idx"
            @click="goRepair(item.remont_id)"
          >
            <td v-if="USER_INFO.status === 'admin'">{{ item.user }}</td>
            <td>{{ item.date_add }}</td>
            <td>{{ item.name_instr }}</td>
            <td>{{ item.serialNumberGroup }}</td>
            <td>{{ item.serialNumber }}</td>
            <td>{{ item.dateSale }}</td>
            <td>{{ item.dateRepair }}</td>
            <td>{{ item.defect }}</td>
            <td>{{ item.seller }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else />
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
import { mapGetters } from "vuex";
export default {
  name: "RepairsList",
  computed: {
    ...mapGetters(["USER_INFO"])
  },
  data: () => ({
    repairs_list: null
  }),
  mounted() {
    HTTPGET({
      url: "repairs/repairs_list.php"
    })
      .then(resp => (this.repairs_list = resp.data))
      .then(() => this.setWidthInputs());
  },
  methods: {
    goRepair(id) {
      this.$router.push(`/repairs/repair-${id}`);
    },
    setWidthInputs() {
      const el = document.getElementsByClassName("hide");
      for (let i = 0; i < el.length; i++) {
        el[i].style.width = el[i].parentNode.offsetWidth * 0.9 + "px";
      }
    }
  }
};
</script>
<style lang="scss" scoped>
table {
  font-size: 0.7em;
}
.table-head {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.7em;
  padding: 3px;
  text-align: center;
  margin-top: 10px;
}
th > input {
  width: 0;
  height: 1.6em;
  margin: auto;
}
th {
  text-align: center;
}
.tr:hover {
  cursor: pointer;
  background: rgb(247, 247, 247);
}
.repairs-list-wrapper {
  overflow: auto;
}
</style>
